<template>
    <div class="youtube">
        <!--<YouTube width="100%" height="100%" :vars="youtubeParams" :src="`https://www.youtube.com/watch?v=${ytid}`" ref="youtube" />-->
        <iframe width="100%" height="100%" :src="`https://www.youtube.com/embed/${ytid}?modestbranding=1`"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</template>

<script>
//import YouTube from 'vue3-youtube'
export default {
    name: "YouTubeComponent",
    components: {
        //YouTube
    },
    props: {
        ytid: String
    },
    data() {
        return {
            youtubeParams: {
                fs:1,
                modestbranding:1,
                rel:0,
                controls:1
            }
        }
    },
}
</script>