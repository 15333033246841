<template>
    <HomePage :class="(showIt) ? '' : 'opacity0'"></HomePage>
    <MediaOverlay></MediaOverlay>
</template>
<script>
import HomePage from './components/pages/HomePage'
import gsap, {} from "gsap/all";
import MediaOverlay from "./components/framework/MediaOverlay";
export default {
    name: 'App',
    components: {
        MediaOverlay,
        HomePage,
    },
    data() {
        return {
            window: window,
            document: document,
            showIt: false
        }
    },
    mounted() {
        this.window.addEventListener('resize', this.resize)
        this.resize()
        setTimeout(this.reveal, 300)
        setTimeout(this.reveal, 1000)
    },
    methods: {
        resize() {
            // get the viewport height and we multiple it by 1% to get a value for a vh unit
            let windowHeight = this.window.innerHeight
            let windowWidth = this.window.innerWidth

            let vh = this.window.innerHeight * 0.01;
            this.document.documentElement.style.setProperty('--vh', `${vh}px`)

            let navElement = document.getElementById('projects-nav')
            let navHeight = (navElement) ? navElement.clientHeight : 0
            let introElement = document.getElementById('block-intro')
            let introHeight = windowHeight - navHeight - 100 //100 = top and bottom padding

            //console.log('windowHeight', windowHeight, navHeight, introHeight )
            this.showIt = (windowWidth<=850 || navHeight) //only show once nav height is loaded

            if (windowWidth<=850) {
                gsap.set(introElement, {
                    height: 'auto'
                })
            } else {
                gsap.set(introElement, {
                    height: introHeight
                })
            }


        },
        reveal() {
            /*
            let tl = gsap.timeline({

            })
            tl.fromTo('#main-bg-image', 3,
                {
                  opacity: 0,
                },
                {
                  opacity: 1,
                  ease: Power3.easeInOut,
                  stagger:0.1
                },
                0
            )
            tl.fromTo('.stagger1', 1.5,
                {
                  opacity: 0,
                },
                {
                  opacity: 1,
                  ease: Power3.easeInOut,
                  stagger:0.1,
                  delay:0,
                },
                0
            )
            tl.fromTo('#header-container', 2,
                {
                  opacity: 0,
                  y:-50
                },
                {
                  opacity: 1,
                  y:0,
                  ease: Power3.easeInOut,
                  delay:0.75
                },
                0
            )

             */
        }

    }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>