<template>
    <div id="mediaOverlay">
        <div id="mediaOverlayScrim"></div>
        <div id="mediaOverlayInner">
            <div class="overlay-x noselect">
                <div class="close-button global-close"></div>
            </div>
            <div class="inner2">
                <div id="swiper-super-outer"></div>
                <div id="media-dots"><div class="dots"></div></div>
            </div>
        </div>
    </div>

</template>
<script>
import 'swiper/css/bundle';
import gsap, {Power4} from "gsap/all";
import $ from 'jquery'
import Swiper from 'swiper'
import { Navigation, Pagination} from 'swiper/modules'
export default {
    name: 'App',
    components: {

    },
    data() {
        return {
            window: window,
            document: document,
            mediaOverlayIsOpen: false,
            swiper:null,
            didInit: false,
            initInterval: null
        }
    },
    mounted() {
        const t = this
        $(document).ready(function() {
            t.initInterval = setInterval(t.initCheck,100)
        }.bind(t))
    },
    methods: {
        initCheck() {
            let count = document.querySelectorAll('.mediaOverlay-hook').length
            if (count > 0) {
                clearInterval(this.initInterval)
                this.doInit()
            }
        },
        doInit() {
            if (this.didInit === false) {
                const t = this
                this.didInit = true
                $('.mediaOverlay-hook').on('click', t.doMediaOverlayHandle);
                $('#mediaOverlay .overlay-x').on('click', t.doMediaOverlayClose);
                $(document).keyup(function (e) {
                    if (t.mediaOverlayIsOpen) {
                        if (e.keyCode === 27) { // escape key
                            t.doMediaOverlayClose()
                        }
                    }
                }.bind(t))
            }

        },
        doMediaOverlayHandle(e) {
            let clickedThis = e.currentTarget
            let initialSlide = $(clickedThis).attr('data-index')
            let group = $(clickedThis).attr('data-group')
            let thtml = ''
            $('.mediaOverlay-hook-' + group).each(function () {
                let tempDiv2 = $(this).prop('outerHTML')
                let tempDiv = `<div class="swiper-slide"><div class="bgimg">${tempDiv2}</div></div>`
                thtml += tempDiv
            })
            let finalhtml = `
                <div class="side prev">
                    <div class="swiper-button-prev"></div>
                </div>
                <div class="swiper">
                    <div class="swiper-wrapper">${thtml}</div>
                </div>
                <div class="side next">
                    <div class="swiper-button-next"></div>
                </div>`
            $('#swiper-super-outer').html(finalhtml)
            this.doMediaOverlayOpenReveal(initialSlide)
        },
        doMediaOverlayOpenReveal: function (initialSlide = 0) {
            this.mediaOverlayIsOpen = true
            this.swiper = new Swiper("#swiper-super-outer .swiper", {
                modules: [Navigation, Pagination],
                init: false,
                loop: true,
                initialSlide: initialSlide,
                spaceBetween: 20,
                slidesPerView: 1,
                slidesPerGroup: 1,
                slideToClickedSlide: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: "#swiper-super-outer .swiper-button-next",
                    prevEl: "#swiper-super-outer .swiper-button-prev",
                },
                pagination: {
                    enabled: true,
                    clickable: true,
                    el: "#media-dots .dots",
                },
            })
            this.swiper.init()
            gsap.fromTo($('#mediaOverlay'), 1,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    delay: 0,
                    ease: Power4.easeInOut,
                    onComplete: this.doMediaOverlayOpenReveal2,
                }
            )
            $('#mediaOverlay').show()
        },
        doMediaOverlayOpenReveal2: function () {

        },
        doMediaOverlayClose: function () {
            gsap.fromTo($('#mediaOverlay'), 0.5,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    delay: 0,
                    ease: Power4.easeInOut,
                    onComplete: this.doMediaOverlayClose2
                }
            );
            this.mediaOverlayIsOpen = false
        },
        doMediaOverlayClose2: function () {
            $('#mediaOverlay').hide()
        }
    }
}
</script>