import { createApp } from 'vue'
import App from './App.vue'

//globals
//import YouTube from 'vue3-youtube'
import InlineSvg from 'vue-inline-svg'
import VueGtag from "vue-gtag-next"
import router from './router'
import { store } from './store'
window.config = {
    apiroot: '/assets/json/',
    apiext: '.json'
}
startApp()

function startApp () {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)


    //ANALYTICS - in your main.js, below loading your router
    if (process.env.VUE_APP_GTAG) {
        app.use(VueGtag, {
            property: {
                id: process.env.VUE_APP_GTAG
            }
        })
    }


    //global inline svg
    app.component('inline-svg', InlineSvg)
    //app.component('YouTube', YouTube)

    app.mount('#app')
}