<template>
    <section v-if="json" id="section-home">
        <header>
            <div class="capper">
                <div class="lr">
                    <div class="l">
                        <div class="rte no-max-width">
                            <h1 class="top-h1-text" @click="gh.scrollToTop">Matthew Dunnerstick: Selected Works</h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <main>
            <div class="capper">
                <div class="rte no-a  no-max-width">
                    <div class="projects">
                        <div v-for="(block, index)  in blocksIntro" v-bind:key="`block-${index}`"
                             :class="`block block-${block.type}`" id="block-intro">
                            <div class="copy" v-html="block.content.copy"></div>
                        </div>
                    </div>
                </div>
                <div class="rte no-max-width">
                    <div class="projects">
                        <div id="projects-nav" class="projects-nav">
                            <div v-for="(block, index) in blocksNav" v-bind:key="`block-${index}`"
                                 :class="[`nav-item nav-item-${block.type}`, isNavSectionActive(index) ? 'active' : '']">
                                <h5>{{ block.label }}</h5>
                                <div v-for="(project, index2)  in block.items"
                                     v-bind:key="`projects-nav-item-${index2}`"
                                     :class="[`item item-${index}`, isNavActive(stripCharacters(project.title)) ? 'active' : '']">
                                    <p :class="( isNavActive(stripCharacters(project.title)) ) ? 'active' : ''"
                                       @click="navClick(stripCharacters(project.title))">{{ project.title }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="blocks">
                            <div :class="['show-mobile scroll-down', (scrolledOnce) ? ' scrolled' : '']">
                                <span class="material-icons">arrow_downward</span>
                            </div>
                            <div v-for="(block, index)  in blocksNav" v-bind:key="`block-${index}`"
                                 :class="`block block-${block.type}`">
                                <div :class="['projects-sections', (scrolledOnce) ? ' scrolled' : '']">
                                    <div v-for="(project, index2) in block.items"
                                         v-bind:key="`projects-section-${index2}`"
                                         :id='`section-${ stripCharacters(project.title)}`'
                                         :class="[`project project-hook project-hook-${index}-${index2}`, project.type, ( isNavActive(stripCharacters(project.title)) ) ? 'active' : '']"
                                         :tid="stripCharacters(project.title)"
                                    >
                                        <h2 class="section-title">{{ project.title }} <span>{{ project.year }} </span>
                                        </h2>
                                        <h5 class="section-subtitle">{{ project.role }}</h5>

                                        <div v-for="(projectInner, index3)  in project.copy"
                                             v-bind:key="`project-inner-${index3}`" class="project2">
                                            <div v-if="projectInner.type==='rte'"
                                                 class="project-inner project-inner-rte"
                                                 v-html="projectInner.copy"></div>
                                            <div v-if="projectInner.type==='link'"
                                                 class="project-inner project-inner-link">
                                                <p><span class="material-icons">link</span> {{ linkText(projectInner) }}<a
                                                    class="link-underline-leftright" :href="projectInner.link"
                                                    target="_blank" v-html="linkTextLink(projectInner)"></a></p>
                                            </div>
                                            <div v-if="projectInner.type==='grid'"
                                                 class="project-inner project-inner-grid">
                                                <div v-for="(gridItem, index4) in projectInner.grid"
                                                     v-bind:key="`griditem-${index4}`" class="grid-item">
                                                    <div v-if="gridItem.type==='img'" class="grid-item2">
                                                        <img
                                                            :class="`mediaOverlay-hook mediaOverlay-hook-${index}-${index2}-${index3}`"
                                                            :data-group="index + '-' + index2 + '-' + index3"
                                                            :data-index="index4" :src="gridItem.path"/>
                                                    </div>
                                                    <div v-if="gridItem.type==='vid'" class="grid-item2">
                                                        <video
                                                            :class="`mediaOverlay-hook mediaOverlay-hook-${index}-${index2}-${index3}`"
                                                            :data-group="index + '-' + index2 + '-' + index3"
                                                            :data-index="index4" :poster="gridItem.poster" loop autoplay
                                                            muted>
                                                            <source :src="gridItem.path" type="video/mp4">
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="projectInner.type==='single-image'"
                                                 class="project-inner project-inner-single-image">
                                                <img
                                                    :class="`mediaOverlay-hook mediaOverlay-hook-${index}-${index2}-${index3}`"
                                                    :data-group="index + '-' + index2 + '-' + index3"
                                                    :data-index="index4"
                                                    :src="projectInner.path"/>
                                            </div>
                                            <div v-if="projectInner.type==='youtube'"
                                                 class="project-inner project-inner-youtube">
                                                <YouTubeComponent :ytid="projectInner.ytid"></YouTubeComponent>
                                            </div>
                                            <div v-if="projectInner.type==='engagements'"
                                                 class="project-inner project-inner-speaker">
                                                <div v-for="(projectInner2, index4) in projectInner.copy"
                                                     v-bind:key="`engagements-${index4}`" class="row">
                                                    <div class="row-l">
                                                        <p>{{ projectInner2.date }}</p>
                                                    </div>
                                                    <div class="row-r">
                                                        <h5>{{ projectInner2.title }}</h5>
                                                        <h6>{{ projectInner2.subtitle }}</h6>
                                                        <p v-if="projectInner2.link"><a rel="noreferrer" target="_blank"
                                                                                        :href="projectInner2.link">Link</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- block -->
                        </div> <!-- blocks -->
                    </div> <!-- projects -->
                </div> <!-- rte -->
            </div> <!-- capper -->
        </main>
    </section>
</template>

<script>
import ScrollReveal from 'scrollreveal'
import gh from "@/helpers/gh"
import axios from "axios"
import {gsap, Power3} from 'gsap/all'
import {ScrollToPlugin} from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollToPlugin)
import YouTubeComponent from "@/components/pieces/YouTubeComponent";

export default {
    name: "HomePage",
    components: {
        YouTubeComponent
    },
    data() {
        return {
            gh: gh,
            window: window,
            document: document,
            json: null,
            activeNav: null,
            scrolledOnce: false
        }
    },
    mounted() {
        const graphQl = `
            query MyQuery {
              globalSets {
                ... on dunnerstickCom_GlobalSet {
                    topBio
                }
              }
              categories {
                ... on dunnerstick_Category {
                  id
                  title
                  label
                  year
                  role
                  contentBlock {
                    ... on contentBlock_richText_BlockType {
                      body
                    }
                    ... on contentBlock_link_BlockType {
                      linkout
                      linkLabel
                      titlePrefix
                    }
                    ... on contentBlock_youtube_BlockType {
                      youtubeId
                      label
                    }
                    ... on contentBlock_grid_BlockType {
                      asset(posterImage: "") {
                        url(transform: "default")
                        ... on dunnerstick_Asset {
                          posterImage {
                            url(transform: "default")
                          }
                        }
                      }
                    }
                    ... on contentBlock_engagements_BlockType {
                      label
                      date
                      speakingType
                      subtitle
                      linkout
                    }
                  }
                  level
                }
              }
            }
        `
        let url = '/content.php'
        if ( process.env.VUE_APP_DEVMODE === 'true') {
            url = 'https://matthewdunnerstick.com/content.php'
        }
        axios.post(url, {
            query: graphQl
        }).then(res => {
            this.graphQlResponse(res)
        }).catch(error => {
            console.error('Error:', error);
        });
    },
    computed: {
        blocksNav: function () {
            const ret = this.json?.blocks?.filter(i => i.type === 'nav')
            if (ret.length) {
                return ret[0].contentCategories
            } else {
                return []
            }
        },
        blocksIntro: function () {
            return this.json?.blocks?.filter(i => i.type === 'intro')
        },
        blocksProjects: function () {
            return this.json?.blocks?.filter(i => i.type === 'projects' || i.type === 'speaking-press')
        }
    },
    methods: {
        graphQlResponse(res) {
            let originalJson = res.data

            let convertedJson = {
                "type": "nav",
                "contentCategories": []
            };

            let currentCategory = null
            originalJson.data.categories.forEach(category => {
                if (category && category.id) {
                    if (category.level === 1) {
                        currentCategory = {
                            "type": category.label,
                            "label": category.title,
                            "items": []
                        }
                        convertedJson.contentCategories.push(currentCategory);
                    } else if (category.level === 2 && currentCategory) {
                        let newItem = {
                            "title": category.title,
                            "year": category.year,
                            "role": category.role,
                            "copy": this.convertContentBlock(category.contentBlock)
                        }
                        currentCategory.items.push(newItem)
                    }
                }
            });

            let topBio = ''
            originalJson.data.globalSets.forEach(obj => {
                if (obj.topBio) {
                    topBio = {
                        "type": "intro",
                        "content": {
                            "title": "Matthew Dunnerstick Selected Artworks & Projects",
                            "copy": obj.topBio
                        }
                    }
                }
            })

            let finalJson = {
                blocks: []
            }

            finalJson.blocks.push(topBio)
            finalJson.blocks.push(convertedJson)
            this.processRespone(finalJson)
        },
        convertContentBlock(contentBlocks) {
            //check for weird engagement style
            if (contentBlocks.length && contentBlocks[0].speakingType) {
                let engagements = {
                    "type": "engagements",
                    "copy": []
                }
                for (let i=0;i<contentBlocks.length;i++) {
                    let tobj = contentBlocks[i]

                    let dt = tobj.date
                    let datePart = dt.split('T')[0]
                    let dateComponents = datePart.split('-')
                    let formattedDate = dateComponents[1] + '/' + dateComponents[2] + '/' + dateComponents[0]

                    let ret = {
                        "date": formattedDate,
                        "type": tobj.speakingType,
                        "title": tobj.label,
                        "subtitle": tobj.subtitle,
                        "link": tobj.linkout
                    }
                    engagements.copy.push(ret)
                }
                return [engagements]
            } else {
                return contentBlocks.map(block => {
                    if (block.body) {
                        return {"type": "rte", "copy": block.body};
                    } else if (block.asset) {
                        if (block.asset.length===1) {
                            return {
                                "type": "single-image",
                                "path": block.asset[0].url
                            };
                        } else {
                            return {
                                "type": "grid",
                                "grid": block.asset.map(asset => {
                                    if (asset.posterImage && asset.posterImage.length > 0) {
                                        return {
                                            "type": "vid",
                                            "path": asset.url,
                                            "poster": asset.posterImage[0].url
                                        };
                                    } else {
                                        return {"type": "img", "path": asset.url};
                                    }
                                })
                            };
                        }
                    } else if (block.linkout) {
                        return {
                            "type": "link",
                            "link": block.linkout,
                            "linkLabel":block.linkLabel,
                            "titlePrefix": block.titlePrefix
                        };
                    }
                }).filter(Boolean); // Filters out undefined entries
            }
        },

        staticResponse(res) {
            this.processRespone(res.data)
        },
        processRespone(json) {
            //GLOBAL CONFIG
            this.json = json

            setTimeout(this.scrollReveals, 1000)

            this.window.addEventListener("scroll", this.homeScroll)
            this.homeScroll()

            this.window.dispatchEvent(new Event('resize'))
        },
        scrollReveals() {
            let slideUp = {
                distance: '40%',
                origin: 'bottom',
                opacity: 0,
                /*
                afterReveal: function(e) {
                    console.log('e', e)
                }
                */
            }
            ScrollReveal().reveal('.project', slideUp)

            this.window.dispatchEvent(new Event('resize'))
        },
        navClick(i) {
            const tid = '#section-' + i
            gsap.to(this.window, {
                duration: 0.5,
                scrollTo: tid,
                ease: Power3.easeInOut
            })
        },
        isNavActive(i) {
            return (this.activeNav === i)
        },
        isNavSectionActive(index) {
            let ret = false
            let arr = this.blocksNav[index]
            for (let i = 0; i < arr.items.length; i++) {
                let obj = arr.items[i]
                let tit = this.stripCharacters(obj.title)
                if (this.isNavActive(tit)) {
                    ret = true
                }
            }
            return ret
        },
        homeScroll() {
            let slides = this.document.getElementsByClassName("project-hook");
            let firstElementInView = null
            let lastElementInView = null
            let tid = null
            for (let i = 0; i < slides.length; i++) {
                let elem = slides[i]
                let tid = elem.getAttribute("tid");
                if (gh.isScrolledIntoView(elem)) {
                    if (firstElementInView === null) {
                        firstElementInView = tid
                    }
                }
                if (i === slides.length - 1) {
                    lastElementInView = tid
                }
            }

            this.activeNav = (firstElementInView) ? firstElementInView : tid
            if ((this.window.innerHeight + this.window.scrollY) >= this.document.body.scrollHeight) {
                if (lastElementInView) {
                    this.activeNav = lastElementInView
                }
            }

            let scrollTop = (this.window.pageYOffset !== undefined) ? this.window.pageYOffset : (this.document.documentElement || this.document.body.parentNode || this.document.body).scrollTop;
            this.scrolledOnce = (scrollTop)

        },
        linkText(obj) {
            let ret = ''
            if (obj.titlePrefix) {
                ret = obj.titlePrefix + ": " + ret
            }
            return ret
        },
        linkTextLink(obj) {
            let ret = obj.link
            if (obj.linkLabel) {
                ret = obj.linkLabel
            }
            return ret
        },
        stripCharacters(txt) {
            return txt.replace(/[^a-zA-Z]/g, "")
        }

    }
}
</script>